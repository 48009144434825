.App {
  text-align: center;
}

.weatherIcon {
  background: #1c9cf6;
  text-align: center;
  border-radius:50%;
  display: inline-block;
  padding:10px;
  margin-bottom: 5vmin;
  display: inline-block;
  height: 10vmin;
  width: 10vmin;
}