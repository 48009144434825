.App {
  text-align: center;
}

.App-header {
  background-color: #3c3c3c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-subheader {
  margin-bottom: 5vh;
}

.App-logo {
  height: 30vmin;
  text-align: center;
  display: inline-block;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.App-block {
  display: "flex";
  flex-wrap: row;
  justify-content: space-between;
  margin-bottom: 5vmin;
  width: 75%;
}

.App-icons {
  display: flex;
  flex-wrap: row;
  justify-content: space-between;
}

.App-icons .App-icon-link a {
  text-align: center;
  display: inline-block;
  margin: 10px;
  color: #ffffff;
}

.App-navigation {
  display: "flex";
  flex-wrap: row;
  justify-content: space-between;
  background-color: #3c3c3c;
}

.App-navigation-link {
  color: #ffffff;
}

.App-navigation-link:hover {
  color: #6e5494;
  text-decoration: none;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link:visited {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #6e5494;
  text-decoration: none;
}

.linkedin a:hover {
  color: #2867B2;
}

.twitter a:hover {
  color: #00acee;
}

.work a:hover {
  color: #0088FF;
}

.github a:hover {
  color: #6e5494;
}

.gitlab a:hover {
  color: #fca326;
}

.instagram a:hover {
  color: #C13584;
}
